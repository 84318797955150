.main_header_div {
  height: 90px;
  background: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;

  .inner_head_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1240px;

    .heading {
      width: 110px;
      height: 70px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .w-address-div {
      display: flex;
      align-items: center;
      gap: 2rem;

      a {
        height: 30px;
      }

      .sign_in_btn {
        width: 220px;
        height: 40px;
        background: var(--button-bg);
        font-weight: 900;
        font-size: 20px;
        color: var(--button-text);
      }
    }

  }
}

@media (max-width: 1250px) {
  .main_header_div {
    .inner_head_div {
      width: 635px;
    }
  }
}

@media (max-width: 760px) {
  .main_header_div {
    .inner_head_div {
      width: 350px;

      .heading {
        width: 100px;
        height: 60px;
      }

      .w-address-div {
        gap: 20px;

        a {
          height: 24px;

          img {
            height: 24px;
            width: 24px;
          }
        }

        .sign_in_btn {
          width: 150px;
          height: 40px;
          font-size: 16px;
        }
      }
    }
  }
}