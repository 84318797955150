.login_main_div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .middle_box_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    width: 650px;
    background: var(--button-text);
    padding: 60px 80px 70px 80px;
    border-radius: 20px;
    margin: auto;
    position: relative;

    .box_heading {
      width: 100%;
      text-align: center;

      img {
        width: auto;
        height: 100px;
      }
    }

    .box_btn_div {
      width: 100%;
      height: 100px;

      .box_btn {
        width: 100%;
        background: var(--button-bg);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        font-family: 'The Sectione Bright', sans-serif;
        font-weight: 400;
        font-size: 100px;
        color: var(--button-text);


        &:active {
          transform: translate(3px, 3px);
        }

        &:hover {
          background: var(--route-bg);
          color: var(--route-text);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .login_main_div {

    .middle_box_div {
      width: 100%;
      margin: auto 2rem;
    }
  }
}

@media (max-width: 600px) {
  .login_main_div {
    .middle_box_div {
      .box_heading {
        img {
          height: 60px;
        }
      }

      .box_btn_div {
        height: 60px;

        .box_btn {
          font-size: 50px;
        }
      }
    }
  }
}