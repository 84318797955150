@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap');

@font-face {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    src: local('Segoe UI'), url('../src/assets/Segoe UI.ttf') format('truetype')
}

@font-face {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 900;
    src: local('Segoe UI'), url('../src/assets/Segoe UI Bold.ttf') format('truetype')
}

@font-face {
    font-family: The Sectione Bright;
    font-style: normal;
    font-weight: 400;
    src: local('The Sectione Bright'), url('../src/assets/FontsFree-Net-zTheSectioneBright-Script.ttf') format('truetype')
}

#root {
    height: 100vh;
    margin: 0;
    background: url("./assets/sloth_bg.png");
    font-family: 'Segoe UI', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
}

#root::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body {
    overflow: auto;
    height: 100vh;
}


@media (max-width: 1250px ) {
    html,
    body {
        height: 100%;
        width: 100%;
        overflow: auto;
    }
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: 'Segoe UI', sans-serif;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
