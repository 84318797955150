.center_div {
  padding: 24px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;

  .middle_div {
    width: 1240px;

    .progress {
      width: 100%;
      background: var(--bg);
      backdrop-filter: blur(16px);
      height: 38px;
      position: relative;
      overflow: hidden;

      .progress-bar {
        background: var(--route-bg);
        height: 100%;
        position: absolute;
        left: 0;
      }

      .absolute_text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 700;
      }
    }

    .nav_div {
      margin: 24px 0 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .navLink {
        transition: all 0.3s ease-in-out;
        opacity: 0.5;
        width: 150px;
        height: 38px;
        font-weight: 700;
        font-size: 18px;
        background: var(--route-bg);
        border: 3px solid var(--route-text);
        border-radius: 8px;
        cursor: pointer;

        li {
          color: var(--route-text);
          width: 100%;
          text-align: center;
          list-style: none;
          font-family: 'Montserrat', sans-serif;
        }

        &:hover {
          opacity: 0.8;
        }

      }

      .navLink.active {
        opacity: 1;
      }

      button:hover {
        box-shadow: none;
      }
    }

    .main_box_div {
      width: calc(100% - 70px);
      padding: 30px 30px 30px 40px;
      height: calc(480px - 60px);
      background: var(--bg);
      border-radius: 20px;
      display: flex;
      justify-content: space-between;

      .left_div {
        width: 530px;

        .box_heading {
          width: 100%;

          img {
            width: 45%;
          }
        }

        .text_desc {
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          line-height: 135%;
          color: #F0F9B6;
        }
      }


      .right_box_div {
        width: 535px;
        height: 100%;
        background: #4D0E76;
        border-radius: 20px;

        .mint_box_middle_div {
          padding: 30px 40px;
          border-bottom: 1px solid #CDD6D7;

          .mind_details_div {
            display: flex;
            margin-bottom: 8px;
            align-items: center;
            justify-content: space-between;

            .first_column, .second_column {
              font-family: 'Montserrat', sans-serif;
              font-size: 18px;
              line-height: 140%;
              color: var(--text);
              text-transform: uppercase;
            }

            .second_column {
              font-weight: 900;
            }
          }

          .total_div {
            margin-bottom: 0;
          }

          .counter_div {
            display: flex;
            margin: 30px 0;
            width: 100%;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: 'Montserrat', sans-serif;
              font-weight: 900;
              font-size: 60px;
              color: var(--text);
            }

            .plus_minus_btn {
              cursor: pointer;
              font-size: 50px;
              font-weight: 600;
              width: 60px;
              height: 60px;
              background: var(--route-bg);
              border: 5px solid var(--route-text);
              color: var(--route-text);
              transition: all 0.3s ease-in-out;
              border-radius: 50px;

              span {
                height: 7px;
                width: 22px;
                background: var(--route-text);
              }

              &:active {
                transform: translate(3px, 3px);
              }

              &:hover {
                background: var(--route-text);
                border: 5px solid var(--route-bg);
                color: var(--route-bg);

                span {
                  background: var(--route-bg);
                }
              }
            }

            .center_count {
              width: calc(100% - 140px);
              height: 70px;
              background: transparent;
            }
          }
        }

        .mint_btn_div {
          height: 144px;
          padding: 0 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .mint_btn {
            width: 100%;
            height: 70px;
            background: var(--button-bg);
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
            font-family: 'The Sectione Bright', sans-serif;
            font-weight: 400;
            font-size: 66px;
            color: var(--button-text);

            &:hover {
              background: var(--route-bg);
              color: var(--route-text);
            }

            &:disabled {
              pointer-events: none;
              opacity: 0.6;
            }

            &:active {
              transform: translate(3px, 3px);
            }
          }
        }

        .error_message {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-weight: 400;
            font-size: 20px;
            color: #F0F9B6;
          }
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .center_div {
    .middle_div {
      width: 635px;

      .nav_div {
        justify-content: space-between;
        gap: 10px;
      }

      .main_box_div {
        height: auto;
        width: calc(100% - 80px);
        padding: 40px;
        flex-direction: column;
        gap: 40px;

        .right_box_div {
          min-height: 300px;

          .mint_btn_div {
            height: 140px;
          }

          .error_message {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .center_div {

    .middle_div {
      width: 350px;

      .progress {
        height: 40px;

        .absolute_text {
          font-size: 18px;
        }
      }

      .nav_div {
        gap: 0;

        .navLink {
          font-size: 14px;
          width: auto;
          padding: 0 12px;

          &:hover {
            opacity: 1;
          }
        }
      }

      .main_box_div {
        width: calc(100% - 50px);
        padding: 1.5rem;
        gap: 20px;
        height: auto;

        .left_div {
          width: auto;

          .box_heading {
            img {
              width: 50%;
            }
          }

          .text_desc {
            width: 100%;
            font-size: 14px;
            line-height: 135%;
          }
        }


        .right_box_div {
          width: 100%;
          height: auto;

          .mint_box_middle_div {
            padding: 1rem;

            .mind_details_div {
              .first_column, .second_column {
                font-size: 14px;
              }
            }

            .counter_div {
              width: 100%;

              div {
                font-size: 30px;
              }

              .plus_minus_btn {
                height: 44px;
                width: 44px;
                font-size: 30px;
                border: 3px solid var(--route-text);

                span {
                  height: 4px;
                  width: 14px;
                }
              }

              .center_count {
                height: 50px;
                width: calc(100% - 100px);
              }
            }
          }

          .mint_btn_div {
            height: 100px;
            padding: 0 1rem;

            p {
              font-size: 16px;
            }

            .mint_btn {
              width: 100%;
              height: 40px;
              font-size: 30px;
            }
          }

          .error_message {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}