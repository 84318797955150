:root {
  --styled-font: 'Segoe UI', sans-serif;
  font-family: 'Segoe UI', sans-serif;
  --text: #F0F9B6;
  --route-text: #DCFC2D;
  --route-bg: #EB465F;
  --button-bg: #E5FE5D;
  --button-text: #1F0238;
  --bg: #330C53;
}

.Toastify__toast-body > div{
  text-align: center;
  color: #000;
  font-size: 18px;
}